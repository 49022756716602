body {
  font-family: "Poppins", sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

#editSidebar::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}